import React, { FC } from 'react';

import { SimpleIFrameProps } from './models';

import './SimpleIFrame.scss';

const SimpleIFrame: FC<SimpleIFrameProps> = ({ videoId, placeholderImage, lang, ariaId }) => (
  <iframe
    loading="lazy"
    src={`https://www.youtube-nocookie.com/embed/${videoId}?rel=0${
      placeholderImage ? '&autoplay=1' : ''
    }&modestbranding=1&enablejsapi=1&cc_load_policy=1&cc_lang_pref=${lang}&hl=${lang}`}
    title="YouTube video player"
    frameBorder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
    allowFullScreen
    data-testid="iframe-test"
    className="iframe-with-image__video"
    aria-describedby={ariaId}
  />
);

export default SimpleIFrame;
