import React, { FC, useContext, useMemo } from 'react';

import { Options } from 'components/VideoYouTube/models';
import YouTubeWithImage from 'components/VideoYouTube/YouTubeWithImage';
import { isBrowser } from 'utils/browser';
import { getYouTubeId } from 'utils/iframeHandler';

import LanguageContext from '../../contexts/languageContext';
import ScreenRecognitionContext from '../../contexts/screenRecognitionContext';
import { VideoIframeProps } from './models';
import SimpleIFrame from './SimpleIFrame';

import './VideoIframe.scss';

const VideoIframe: FC<VideoIframeProps> = ({
  url,
  placeholderImage,
  playButtonText,
  onPlayHandle,
  onPauseHandle,
  onEndHandle,
  onStateChangeHandle,
  isPulledCaseStudies,
  isImageAndVideoSlider,
  ariaId,
}) => {
  const lang = useContext(LanguageContext);
  const protocol = isBrowser() ? window.location.protocol : 'https';
  const videoId = useMemo(() => (url ? getYouTubeId(url) : undefined), [url]);
  const { isTablet, isMobile, isSmallMobile } = useContext(ScreenRecognitionContext);

  const opts: Options = {
    host: `${protocol}//www.youtube-nocookie.com`,
    playerVars: {
      autoplay: placeholderImage ? 1 : 0,
      cc_load_policy: 1,
      cc_lang_pref: String(lang),
      hl: String(lang),
    },
  };

  const onPlayerReady = (e) => {
    e.target.getIframe().setAttribute('aria-describedby', ariaId);
    if (isMobile || isSmallMobile || isTablet) e.target.mute();
    e.target.playVideo();
  };

  return (
    <div className="iframe-with-image">
      {placeholderImage ? (
        <div className="youtube-with-image">
          <YouTubeWithImage
            videoId={videoId}
            opts={opts}
            onReadyHandle={onPlayerReady}
            onPlayHandle={onPlayHandle}
            onPauseHandle={onPauseHandle}
            onEndHandle={onEndHandle}
            onStateChangeHandle={onStateChangeHandle}
            playButtonText={playButtonText}
            placeholderImage={placeholderImage}
            isPulledCaseStudies={isPulledCaseStudies}
            isImageAndVideoSlider={isImageAndVideoSlider}
            ariaId={ariaId}
          />
        </div>
      ) : (
        <SimpleIFrame
          videoId={videoId}
          placeholderImage={placeholderImage}
          lang={lang}
          ariaId={ariaId}
        />
      )}
    </div>
  );
};

export default VideoIframe;
