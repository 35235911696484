import React, { FC } from 'react';

import Arrow from '../icons/arrow-right.svg';
import { CaptionProps } from './models';

import './Caption.scss';

const Caption: FC<CaptionProps> = ({ text, ariaId, isRte }) => (
  <div className="caption-container">
    <Arrow aria-hidden="true" focusable="false" />
    {isRte ? (
      <div className="caption" dangerouslySetInnerHTML={{ __html: text }} id={ariaId} />
    ) : (
      <div className="caption" id={ariaId}>
        {text}
      </div>
    )}
  </div>
);

export default Caption;
