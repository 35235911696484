import React, { FC, useState } from 'react';
import YouTube from 'react-youtube';

import Image from 'common/Image';
import ArticleCaseStudiesImage from 'common/images/ArticleCaseStudiesImage';
import ArticleMainImage from 'common/images/ArticleMainImage';

import Play from '../../../common/icons/play.svg';
import { YouTubeWithImageProps } from './models';

import './YouTubeWithImage.scss';

const YouTubeWithImage: FC<YouTubeWithImageProps> = ({
  videoId,
  opts,
  onReadyHandle,
  onPlayHandle,
  onPauseHandle,
  onEndHandle,
  onStateChangeHandle,
  playButtonText,
  placeholderImage,
  isPulledCaseStudies,
  isImageAndVideoSlider,
  ariaId,
}) => {
  const [isPlayButtonClicked, setIsPlayButtonClicked] = useState<boolean>(false);

  const playButtonClickHandle = () => {
    setIsPlayButtonClicked(true);
  };

  return (
    <>
      {isPlayButtonClicked ? (
        <YouTube
          videoId={videoId}
          opts={opts}
          onReady={onReadyHandle}
          onPlay={onPlayHandle}
          onPause={onPauseHandle}
          onEnd={onEndHandle}
          onStateChange={onStateChangeHandle}
          className="youtube-with-image__video"
          aria-describedby={ariaId}
        />
      ) : (
        <>
          <button
            className="youtube-with-image__play btn btn--white inner-outline"
            type="button"
            onClick={playButtonClickHandle}
          >
            {playButtonText}
            <Play aria-hidden="true" />
          </button>
          {isPulledCaseStudies ? (
            <ArticleCaseStudiesImage
              className="youtube-with-image__image"
              imageData={placeholderImage}
              alt={placeholderImage.altText}
              aria-describedby={ariaId}
            />
          ) : (
            <>
              {isImageAndVideoSlider ? (
                <ArticleMainImage
                  className="youtube-with-image__image"
                  imageData={placeholderImage}
                  alt={placeholderImage.altText}
                  aria-describedby={ariaId}
                />
              ) : (
                <Image
                  className="youtube-with-image__image"
                  imageData={placeholderImage}
                  alt={placeholderImage.altText}
                  aria-describedby={ariaId}
                />
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default YouTubeWithImage;
