import React, { FC } from 'react';
import { GatsbyImage, getImage, IGatsbyImageData, withArtDirection } from 'gatsby-plugin-image';

import { ImagesContent } from '../../../@types/content/images';
import { getArtDirectedArticleCaseStudiesImages } from './utils';

const defaultBreakpoints: ImagesContent.ArticleCaseStudiesImageBreakpoint[] = [
  { alias: 'caseStudiesMobile', media: '(max-width: 768px)' },
  { alias: 'caseStudiesTablet', media: '(max-width: 1200px)' },
];

const ArticleCaseStudiesImage: FC<ImagesContent.ArticleCaseStudiesImageProps> = ({
  imageData,
  alt,
  breakpoints = defaultBreakpoints,
  ...restProps
}) => {
  const isArtDirected = Object.keys(imageData).includes('caseStudiesDesktop');
  const mainImage = isArtDirected
    ? getImage((imageData as ImagesContent.ArticleCaseStudiesImage).caseStudiesDesktop)
    : getImage(imageData as ImagesContent.ImageDataLike);

  const images = withArtDirection(
    mainImage as IGatsbyImageData,
    getArtDirectedArticleCaseStudiesImages(
      breakpoints,
      imageData as ImagesContent.ArticleCaseStudiesImage
    )
  );

  return <GatsbyImage image={images} alt={alt} {...restProps} />;
};

export const createImageWithBreakpoints =
  (
    breakpoints: ImagesContent.ArticleCaseStudiesImageBreakpoint[]
  ): FC<ImagesContent.ArticleCaseStudiesImageProps> =>
  (props: ImagesContent.ArticleCaseStudiesImageProps) =>
    <ArticleCaseStudiesImage {...props} breakpoints={breakpoints} />;

export default ArticleCaseStudiesImage;
