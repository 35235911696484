import { getImage, IArtDirectedImage, IGatsbyImageData } from 'gatsby-plugin-image';

import { ImagesContent } from '../../../@types/content/images';

export const getArtDirectedArticleMainImages = (
  breakpoints: ImagesContent.ArticleMainImageBreakpoint[],
  imageData: ImagesContent.ArticleMainImage
): IArtDirectedImage[] =>
  breakpoints
    .filter(({ alias }) => !!getImage(imageData[alias]))
    .map(({ alias, media }) => ({
      media,
      image: getImage(imageData[alias]) as IGatsbyImageData,
    }));
